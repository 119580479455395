<template>
  <el-drawer title="商户详情" :visible.sync="show_detail" direction="rtl" size="60%" @opened="opened">
    <div class="wrapper">
      <div class="user-box">
        <el-image style="width: 60px; height: 60px; border-radius: 5px" :src="detail.cover" :preview-src-list="[detail.cover]" fit="cover"></el-image>
        <div class="user">
          <div class="name">{{ detail.name }}</div>
          <div class="name">
            <el-tag type="primary" size="mini">{{ detail.statusDsc }}</el-tag>
          </div>
          <div class="mobile">{{ detail.contact }}</div>
        </div>
      </div>
      <!-- tab -->
      <div class="tabs-box">
        <el-tabs v-model="activeName">
          <el-tab-pane label="基本信息" name="basicInfo">
            <el-descriptions class="margin-top" :column="2" border>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  商户ID
                </template>
                {{ detail.id }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  商户名称
                </template>
                {{ detail.name }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                  联系方式
                </template>
                {{ detail.contact }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-address"></i>
                  商家地址
                </template>
                {{ detail.address }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 商家寄语</template>
                {{ detail.shopMsg }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 创建日期</template>
                {{ detail.createTime }}
              </el-descriptions-item>
                <!-- <el-descriptions-item v-if="detail.category">
                <template slot="label"> 商家分类</template>
                {{ detail.category.name }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label" v-if="detail.category"> 商家分类类型</template>
                {{ detail.category.typeDsc }}
              </el-descriptions-item> -->
            </el-descriptions>

          <el-descriptions v-if="detail.category" class="margin-top" :column="1" border>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  商家分类id
                </template>
                {{ detail.category.id }}
              </el-descriptions-item>
                <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  商家分类名称
                </template>
                {{ detail.category.name }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  商家分类类型
                </template>
                {{ detail.category.typeDsc }}
              </el-descriptions-item>
            </el-descriptions>
          </el-tab-pane>
          <el-tab-pane label="钱包信息" name="balance">
            <div v-if="detail.shopUser" class="balance animate__animated animate__faster animate__zoomIn">
              <div class="item">
                <div class="label">萝卜币</div>
                <div class="value">{{ detail.shopUser.totalCoin }}</div>
              </div>
              <div class="item">
                <div class="label">可提现</div>
                <div class="value">{{ detail.shopUser.availableCoin }}</div>
              </div>
              <div class="item">
                <div class="label">冻结</div>
                <div class="value">{{ detail.shopUser.freezeCoin }}</div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="银行卡" name="card">
            <div
              v-if="detail.userBankAccountList && detail.userBankAccountList.length > 0"
              class="card-list animate__animated animate__faster animate__zoomIn"
            >
              <div class="card" v-for="(item, i) in detail.userBankAccountList" :key="i">
                <div class="bank">{{ item.bankName }}</div>
                <div class="name">
                  <span>{{ item.holderName }}</span>
                  <span> <span style="font-size: 14px">默认：</span>{{ item.isDefault ? '是' : '否' }} </span>
                </div>
                <div class="no">{{ item.cardNumber }}</div>
              </div>
            </div>
            <el-empty v-else description="无"></el-empty>
          </el-tab-pane>
          <el-tab-pane label="用户流水" name="bill">
            <el-table :data="list" style="width: 100%">
              <el-table-column prop="title" label="标题" min-width="160"></el-table-column>
              <el-table-column prop="beforeTotalCoin" label="使用前总萝卜币" width="100"></el-table-column>
              <el-table-column prop="afterTotalCoin" label="使用后总萝卜币" width="100"></el-table-column>
              <el-table-column prop="beforeCoin" label="使用前可使用萝卜币" width="100"></el-table-column>
              <el-table-column prop="afterCoin" label="使用后可使用萝卜币" width="100"></el-table-column>
              <el-table-column prop="beforeFreezeCoin" label="使用前冻结萝卜币" width="100"></el-table-column>
              <el-table-column prop="afterFreezeCoin" label="使用后冻结萝卜币" width="100"></el-table-column>
              <el-table-column prop="coin" label="萝卜币" width="100">
                <template slot-scope="{ row }"> {{ row.isFuShu ? '-' : '+' }} {{ row.coin }}</template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="200"></el-table-column>
            </el-table>
            <pagination :total="total" :page.sync="page" :limit.sync="pagesize" @pagination="getList" />
            <div style="height: 30px"></div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { getBillListAPI, getDetailAPI } from './api'

export default {
  name: 'Detail',
  props: {},
  data() {
    return {
      show_detail: false,
      activeName: 'basicInfo',
      list: [],
      total: 0,
      page: 1,
      pagesize: 10,
      inviter_page: 1,
      inviter_pagesize: 10,
      inviter_total: 0,
      inviter_list: [],
      detail: {},
      id: ''
    }
  },

  watch: {},

  mounted() {},

  methods: {
    opened() {
      this.getDetail()
    },
    // 详情
    async getDetail() {
      this.detail = await getDetailAPI(this.id)
      this.getList()
      this.getInviterList()
    },
    // 钱包
    async getList() {
      const params = { page: this.page, pagesize: this.pagesize, shopId: this.detail.id }
      console.log(params)
      const res = await getBillListAPI(params)
      this.list = res.records
      this.total = res.totalRow
    },
  }
}
</script>
<style lang="scss" scoped>
.wrapper /deep/ {
  .user-box {
    display: flex;
    padding: 20px;
    // border-bottom: 1px solid #f5f5f5;
    .user {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;

      .name {
        font-weight: 600;
      }
    }
  }

  .tabs-box {
    padding: 0 20px;
  }

  .card-list {
    display: grid;
    grid-template-columns: repeat(2, 280px);
    gap: 20px;
    justify-content: center;

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: linear-gradient(180deg, #a5a5a5 0%, #7a7a7a 100%);
      height: 140px;
      width: 280px;
      border-radius: 5px;
      padding: 20px;

      .bank,
      .name,
      .no {
        background: linear-gradient(to right, #e2e0e0, #faf1f1);
        /* 从左到右渐变 */
        -webkit-background-clip: text;
        /* Safari/Chrome */
        -webkit-text-fill-color: transparent;
        /* Safari/Chrome */
        color: transparent;
        /* Firefox/Opera */
        font-weight: 600;
      }

      .bank {
        font-size: 18px;
      }

      .name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
      }

      .no {
        font-size: 16px;
      }
    }
  }

  .balance {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    background: #f5f5f5;
    padding: 20px;
    border-radius: 5px;

    .item {
      text-align: center;

      .label {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .value {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .pagination-container {
    padding: 0 20px !important;
  }
  .inviter {
    display: inline-flex;
    align-items: center;
    background: #f8f8f9;
    border-radius: 5px;
    padding: 0 20px;
    .inviter-info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .inviter-name {
        font-weight: 600;
      }
    }
  }
  .balance-box {
    display: flex;
    // align-items: center;
    .balance-item {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      background: #f5f5f5;
      border-radius: 5px;
      padding: 0 20px;
      margin-right: 20px;
      span {
        &:last-child {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
